import React from 'react';
import { Wrapper } from '../elements/layouts';
import NavLink from '../components/nav-link';
import { HEADING_LARGE } from '../elements/typography';

const default404 = () => {
  return (
    <Wrapper>
      <HEADING_LARGE css={{ textAlign: 'center', display: 'block' }}>
        Page can't be found.
      </HEADING_LARGE>
      <NavLink url={'/'} menuLink={true}>
        Back Home
      </NavLink>
    </Wrapper>
  );
};

export default default404;
